<template>
  <div>
    <b-collapse
        v-for="faq in faqs"
        :open="false"
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3">
      <template #trigger="props">
        <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3">
          <p class="card-header-title">
            {{faq.q}}
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'minus' : 'plus'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content" style="white-space: pre-wrap;">
          <p>{{faq.a}}</p>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "FaqsList",
  data() { return {
    faqs: [
      {
        q: "What is Career Fair?",
        a: "The Career Fair is an annual event held at the Ateneo Law School to enable law students to prepare for the apprenticeship program and consequently for future employment. The event is organized by the Sophomores, with the guidance of the school’s administration, and a little help from our freshmen volunteers. It will prepare students with the necessary skills and tools during the application process and more importantly, connect them with law firms and organizations they may work with in the future."
      },
      {
        q: "What should I expect in my internship?",
        a: "The internship is meant to enable students to learn the ins and outs of the law profession. However, the success of your internship will depend on how much you want to learn.  The interest and commitment a student puts into the work he/she does in his/her respective apprenticeship experience will determine the skills, insights, and development that he/she will eventually reap.\n" +
            "\n" +
            "Expect to explore and discover your compatibility and interest with respect to a specific law practice area in your internship experience—keeping in mind that the effort you put in also varies your experience."
      },
      {
        q: "How do you know which firm to apply to? Should I already know which specialization to pursue?",
        a: "If you are interested in a specific practice area with respect to the law profession, make sure to read through the write-ups of each firm and to attend the firm talks in order to spot their areas of expertise. \n" +
            "\n" +
            "The Career Fair is a great start if you still don’t know which firm you want to apply to. It’s better if you already know what to pursue so that you can pick a firm which specializes in that particular fieldm  but don’t worry if you’re still unsure because the internship experience is a great opportunity to explore. \n" +
            "\n" +
            "Either way, it would be wise to do your own research on the firms."
      },
      {
        q: "When do we usually submit an internship application, given that our internship duration would be from mid June to July of 2023?",
        a: " Submit early! Firms accept only a limited number of interns and ALS isn’t the only law school which requires internships. (Other firms also accept interns from law schools that don’t require internships.)\n" +
            "\n" +
            "As much as possible, apply to the firms as early as February-March."
      },
      {
        q: "What happens if I am unable to secure an internship?",
        a: " You can always render your hours the following year. However, it is recommended that it be done as soon as you complete your second year because you will be busy with your thesis next year.\n" +
            "\n" +
            "You can apply for internships in any firm as long as it is accredited by the ALS administration.\n"
      },
      {
        q: "How to go about the internship process? What are the steps?",
        a: "Prepare all the documents that you need (CV, Transcript of Records, Cover Letter) then just email the firms that you want to apply to. \n" +
            "\n" +
            "Usually, they’ll email back with an interview date but some firms don’t host interviews anymore and will immediately offer you a slot. \n" +
            "\n" +
            "NOTE: The Tracking Orientation of the Career Fair on February 14, 2023 is a great avenue to flesh out what these “steps” would entail. This is part of the 2-week Career Fair proper which students are required to attend. The same orientation will provide insights on which tracks students can take in adherence to the chosen fields that they wish to pursue in their law profession."
      },
      {
        q: "What is the time window for the different steps of the internship process?",
        a: "The whole internship application process takes about 2-3 weeks. A suggestion from the Executive Committee is to have three target firms and to set specific standards on choosing the firms (e.g. depending on the field of law profession you wish to pursue, the proximity from your place of residence, and the work hours).\n" +
            "\n" +
            "Ultimately, it will depend on how fast the firm will respond."
      },
      {
        q: "How did you prepare? Did you review your first and second year subjects before the internship?",
        a: "Most students undergoing the apprenticeship program do not review their 1st and 2nd year subjects before the internship. Interviews will seldom focus on purely theoretical questions as well. However, the need to brush up on old topics might come later on, when tasks are already clearly laid down by the firms and certain law principles are required in order to finish a certain task. \n" +
            "\n" +
            "Ultimately, the most important thing is to have an open mind in dealing with your facilitators in your firms. Think of it as acting like a sponge that’s ready to absorb all the skills and tips that your supervising lawyers would offer.\n"
      },
      {
        q: "How do I maximize my internship?",
        a: "Be proactive. The productivity of your entire internship depends on you. Ask for tasks and finish them promptly so you can ask for more. Ask your supervising lawyer questions when there’s something you don’t understand. Don’t be shy and really make the most out of this opportunity."
      },
      {
        q: "Do firms require interns to report on site?",
        a: "This will depend on the firms you apply to. Public offices (like the Public Attorney’s Office) required purely on site work the previous year. Some private firms had an online setup. Just keep in mind that we have almost fully transitioned to onsite activities across the country so this will most probably change by June or July of 2023. Make sure to ask them during the interview so you can plan ahead!"
      }
    ]
  }}
}
</script>

<style scoped>
.card {
  margin-bottom: 12px;
}
</style>